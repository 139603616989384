var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      {
        staticClass: "container mb-5 mt-5 text-center",
        attrs: { id: "globalContainer" }
      },
      [
        _c("h1", { staticClass: "mb-1" }, [
          _vm._v("Vous êtes maintenant connecté")
        ]),
        _vm._m(0),
        _c("div", { staticClass: "row justify-content-center" }, [
          _c(
            "div",
            {
              staticClass:
                "col-xl-3 col-lg-3 cold-md-3 cold-sm-12 col-12 btn-account",
              on: {
                click: function($event) {
                  return _vm.$router.push("/private/account")
                }
              }
            },
            [
              _c(
                "div",
                [
                  _c("icon", {
                    staticClass: "mt-4 mb-4",
                    attrs: { name: "user-circle", scale: "4", fill: "white" }
                  })
                ],
                1
              ),
              _vm._m(1)
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "col-xl-3 offset-xl-1 col-lg-3 offset-lg-1 col-md-3 offset-md-1 col-sm-12 col-12 btn-account",
              on: {
                click: function($event) {
                  return _vm.$router.push("/private/payments")
                }
              }
            },
            [
              _c(
                "div",
                [
                  _c("icon", {
                    staticClass: "mt-4 mb-4",
                    attrs: { name: "credit-card", scale: "4.5", fill: "white" }
                  })
                ],
                1
              ),
              _vm._m(2)
            ]
          ),
          _vm.hasBoxes
            ? _c(
                "div",
                {
                  staticClass:
                    "col-xl-3 offset-xl-1 col-lg-3 offset-lg-1 col-md-3 offset-md-1 col-sm-12 col-12 btn-account",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/private/box")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c("icon", {
                        staticClass: "mt-4 mb-4",
                        attrs: { name: "box-open", scale: "5", fill: "white" }
                      })
                    ],
                    1
                  ),
                  _vm._m(3)
                ]
              )
            : _vm._e(),
          _vm.hasTvs
            ? _c(
                "div",
                {
                  staticClass:
                    "col-xl-3 offset-xl-1 col-lg-3 offset-lg-1 col-md-3 offset-md-1 col-sm-12 col-12 btn-account",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/private/tv")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c("icon", {
                        staticClass: "mt-4 mb-4",
                        attrs: { name: "tv", scale: "5", fill: "white" }
                      })
                    ],
                    1
                  ),
                  _vm._m(4)
                ]
              )
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("i", [
        _vm._v("Vous pouvez gérer votre compte à l'aide du menu ci-dessous.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h4", [_vm._v("MON"), _c("br"), _vm._v("COMPTE")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h4", [_vm._v("MES"), _c("br"), _vm._v("PAIEMENTS")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h4", [_vm._v("MES KITS"), _c("br"), _vm._v("EMOTIVI")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h4", [_vm._v("MES TVs"), _c("br"), _vm._v("EMOTIVI")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }