var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      {
        staticClass: "container pb-5 containercolored",
        staticStyle: { "background-color": "rgb(24, 155, 176)" }
      },
      [
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "row align-items-end" }, [
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass: "col-4 col-sm-4 col-lg-2 text-center mb-3",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/private/payments")
                    }
                  }
                },
                [_vm._m(1)]
              ),
              _vm.hasBoxes
                ? _c(
                    "div",
                    {
                      staticClass: "col-4 col-sm-4 col-lg-2 text-center mb-3",
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/private/box")
                        }
                      }
                    },
                    [_vm._m(2)]
                  )
                : _vm._e(),
              _vm.hasTvs
                ? _c(
                    "div",
                    {
                      staticClass: "col-4 col-sm-4 col-lg-2 text-center mb-3",
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/private/tv")
                        }
                      }
                    },
                    [_vm._m(3)]
                  )
                : _vm._e()
            ])
          ])
        ]),
        _c("div", { staticStyle: { display: "block" } }, [
          _c(
            "div",
            [
              _vm.row_co.co_id
                ? _c("form-contact", {
                    attrs: { row_co: _vm.row_co, disableLogin: false },
                    on: { checkphone: _vm.checkPhone }
                  })
                : _vm._e(),
              _c("div", { staticClass: "mt-3" }, [
                _vm.dialogErrTxt
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v(" " + _vm._s(_vm.dialogErrTxt) + " ")
                    ])
                  : _vm._e(),
                _vm.dialogOkTxt
                  ? _c("div", { staticClass: "alert alert-success" }, [
                      _vm._v(" " + _vm._s(_vm.dialogOkTxt) + " ")
                    ])
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.saveAccount }
                  },
                  [_vm._v(" ENREGISTRER MES COORDONNÉES ")]
                )
              ])
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12 col-lg-5 pt-4" }, [
      _c("div", { staticClass: "mb-3" }, [
        _c("img", {
          staticStyle: { width: "70px" },
          attrs: { src: "/images/ico-interface.png", alt: "" }
        })
      ]),
      _c("div", [_c("h2", [_vm._v("MON COMPTE")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "part-subtitle-private" }, [
      _vm._v("MES"),
      _c("br"),
      _vm._v("PAIEMENTS")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "part-subtitle-private" }, [
      _vm._v("MES KITS"),
      _c("br"),
      _vm._v("EMOTIVI")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "part-subtitle-private" }, [
      _vm._v("MES TVs"),
      _c("br"),
      _vm._v("EMOTIVI")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }